import React from "react";

const Profile = () => {
  return (
    <div>
      Profile
      <svg
        class="animate-spin h-5 w-5 bg-black text-white"
        viewBox="0 0 24 24"
      ></svg>
    </div>
  );
};

export default Profile;
